import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/Layout';

const GeorgFischerPage = () => {
  const data = useStaticQuery(graphql`
    query {
      gf: file(relativePath: { eq: "gf.png" }) {
        ...projectImage
      }
    }
  `);

  return (
    <Layout>
      <div id="main">
        <div className="inner">
          <h1>Georg Fischer – PartInspector</h1>
          <ul>
            <li>
              Designed and developed an <strong>IoT solution</strong> to
              visualize <strong>complex three-dimensional parts</strong>{' '}
              produced by GF machines
            </li>
            <li>
              <strong>Coached two software development teams</strong> in Agile,
              Lean and Continuous Delivery approaches and taught the necessary
              tech skills
            </li>
            <li>
              Solved performance challenges related to each GF machine uploading{' '}
              <strong>5000 datapoints/s</strong> to the cloud and displaying in
              React front-end
            </li>
          </ul>
          <span className="image main">
            <Img fluid={data.gf.childImageSharp.fluid} alt="part inspector" />
          </span>
        </div>
      </div>
    </Layout>
  );
};

export default GeorgFischerPage;
